import type { PlateType, RegistrationOffice, SuggestionType, WeakVehicleType } from '@black-bird/types'

export default defineStore('wkz-search', () => {
  const office = ref<RegistrationOffice>()
  const token = ref('')
  const letters = ref('')
  const numbers = ref('')
  const vehicle = ref<WeakVehicleType>('car')
  const plateType = ref<PlateType>('standard')
  const seasonFrom = ref(4)
  const seasonTo = ref(10)
  const suggestionType = ref<SuggestionType>('all')

  return {
    office,
    token,
    letters,
    numbers,
    vehicle,
    plateType,
    seasonFrom,
    seasonTo,
    suggestionType
  }
}, {
  persist: {
    storage: persistedState.sessionStorage
  }
})
