import 'posthog-js/dist/recorder'
import { posthog } from 'posthog-js'

export default defineNuxtPlugin(() => {
  if (useRuntimeConfig().public.target !== 'production'){
    return
  }
  
  const posthogClient = posthog.init('phc_HTdWzpo03wBPM7sVnW6LTYgHGWTbzBLHqg3vX4PdbS2', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false,
    disable_session_recording: true
  })
  
  const router = useRouter()

  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath
      })
      
      if (to.fullPath.includes('neues-kennzeichen')) {
        posthogClient?.startSessionRecording()
      }
    })
  })


  return {
    provide: {
      posthog: posthogClient
    }
  }
})
