function getClientIdPromise () {
  if (!useNuxtApp().$gtag) return null
  return new Promise(resolve => useNuxtApp().$gtag('get', useRuntimeConfig().public.googleAnalyticsId as string, 'client_id', resolve)) as Promise<string>
}

export default function () {
  return Promise.race([
    getClientIdPromise().catch(() => null),
    new Promise(resolve => setTimeout(resolve, 2000))
  ])
}
