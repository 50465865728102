function removeQueryParams (keys: string[]) {
  const query = { ...useRoute().query }
  for (const key of keys) {
    delete query[key]
  }
  useRouter().replace({ query })
}

export default defineNuxtPlugin(() => {
  const { email, pin, kombination } = useRoute().query

  if (typeof email === 'string') {
    useSharedOrderInfo().email.value = email as string
  }

  const { data: wiederzulassungData } =  storeToRefs(useWiederzulassung())
  const { data: umschreibungData } =  storeToRefs(useUmschreibung())

  if (typeof pin === 'string') {
    wiederzulassungData.value.naechstesKennzeichen.pin = pin as string
    umschreibungData.value.naechstesKennzeichen.pin = pin as string
  }
  
  
  if (typeof kombination === 'string') {
    const [token, letters, numbers] = (kombination as string).split('-')
    wiederzulassungData.value.naechstesKennzeichen.kombination = { token, letters, numbers }
    umschreibungData.value.naechstesKennzeichen.kombination = { token, letters, numbers }
  }

  

  setTimeout(() => {
    removeQueryParams(['email', 'pin', 'kombination'])
  }, 1000)
})
