import type { Search, Plate } from '@gks/types'
import type { PlateType } from '@black-bird/types'
import {
  motorcyclePlateSizes, carPlateSizes,
  tractorPlateSizes, lightMotorcyclePlateSizes
} from '../constants'

export default function (search: Search) {
  const productOptions: { option: string, value: PlateType }[] = [
    { option: 'KO-STD', value: 'standard' },
    { option: 'KO-S', value: 'season' },
    { option: 'KO-H', value: 'oldtimer' },
    { option: 'KO-E', value: 'electric' },
    { option: 'KO-G', value: 'green' },
    { option: 'KO-HS', value: 'oldtimer-season' }
  ]

  const optionAttribute = productOptions.find(option => option.value === search.plate.plateType)?.option
  const productId: string = getProductIdFromPlateData(search.plate)
  const sizeAttribute: string = `KG-${search.plate.size}`
  const filmAttribute: 'KF-C' | 'KF-S' = search.plate.film === 'carbon' ? 'KF-C' : 'KF-S'

  return `${productId}.${sizeAttribute}.${optionAttribute}.${filmAttribute}`
}

function getProductIdFromPlateData (plateData: Plate): string {
  const { motorcycleWKZProductId, carWKZProductId, tractorWKZProductId, lightMotorcycleWKZProductId } = useProductIds()

  if (motorcyclePlateSizes.includes(plateData.size)) return motorcycleWKZProductId
  if (carPlateSizes.includes(plateData.size)) return carWKZProductId
  if (tractorPlateSizes.includes(plateData.size) && plateData.plateType === 'green') return tractorWKZProductId
  if (lightMotorcyclePlateSizes.includes(plateData.size) && plateData.plateType !== 'green') return lightMotorcycleWKZProductId

  throw new Error('Could not find productId for plateData')
}
