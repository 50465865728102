import { default as agbh99VXTzrOeMeta } from "/vercel/path0/layers/base/pages/agb.vue?macro=true";
import { default as daten_45korrigierenrO0Ksm2GjlMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue?macro=true";
import { default as fin3x1wlAOIRDMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue?macro=true";
import { default as generellcpwFBDX6zEMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue?macro=true";
import { default as reservierungcJlHMY13vFMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue?macro=true";
import { default as sicherheitscode_45zb11iCijwbB8VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as indexsBk8334br6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue?macro=true";
import { default as orderK456w9mH1VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue?macro=true";
import { default as fertigYCJr69cVHpMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue?macro=true";
import { default as indexco3tI9qwy6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue?macro=true";
import { default as sc_45hintenHFfWWiaAm9Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue?macro=true";
import { default as sc_45vorn5euoZ1U7wvMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue?macro=true";
import { default as sc_45zb19z9DdbEWn4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue?macro=true";
import { default as zb1ztV6QSFErLMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue?macro=true";
import { default as vorbereitungNBPdgYneSVMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue?macro=true";
import { default as datenschutzjl1RybKV6eMeta } from "/vercel/path0/layers/base/pages/datenschutz.vue?macro=true";
import { default as altes_45kennzeichenF3tjy3YzhbMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeT7nQM93jyFMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/behoerde.vue?macro=true";
import { default as findJXjqKey5dMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/fin.vue?macro=true";
import { default as sicherheitscode_45zb1jVoPJv7byQMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as orderkheOuTObsRMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/order.vue?macro=true";
import { default as vorbereitungbSQmBjfADUMeta } from "/vercel/path0/layers/zulassung/pages/halteraenderung/vorbereitung.vue?macro=true";
import { default as impressumlDRZsWB8OXMeta } from "/vercel/path0/layers/base/pages/impressum.vue?macro=true";
import { default as indexApZSyMy6cWMeta } from "/vercel/path0/apps/zulassung-digital/pages/index.vue?macro=true";
import { default as _91district_93S6iD3MK3YwMeta } from "/vercel/path0/apps/zulassung-digital/pages/landkreis/[district].vue?macro=true";
import { default as landkreisehiIl0WSYFOMeta } from "/vercel/path0/apps/zulassung-digital/pages/landkreise.vue?macro=true";
import { default as _91city_93tlUk7RvyMWMeta } from "/vercel/path0/apps/zulassung-digital/pages/stadt/[city].vue?macro=true";
import { default as staedteILCBVc6XWzMeta } from "/vercel/path0/apps/zulassung-digital/pages/staedte.vue?macro=true";
import { default as altes_45kennzeichen47EHQGQyreMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeVJfXP1g6uBMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/behoerde.vue?macro=true";
import { default as fin4ht25CFaeWMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/fin.vue?macro=true";
import { default as neues_45kennzeichencvIcSH8TedMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/neues-kennzeichen.vue?macro=true";
import { default as nummer_45zb2d9FlIXgRDlMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/nummer-zb2.vue?macro=true";
import { default as reservierung1wlC7M3O3sMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45hintenZuec3ZLB1mMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-hinten.vue?macro=true";
import { default as sicherheitscode_45vorneSRTvE9RCGpMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-vorne.vue?macro=true";
import { default as sicherheitscode_45zb1qPQiSR25jZMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb2FMDaZSiwNOMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steuer5vKk1KgEV2Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerde9F6zyBXj2aMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45sucheOyGyRT48DCMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as fahrzeugRIUeYaojV3Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/fahrzeug.vue?macro=true";
import { default as indexgXu3iQ158cMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/index.vue?macro=true";
import { default as kfz_45kennzeichenTQKaFV8MPmMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/kfz-kennzeichen.vue?macro=true";
import { default as orderwLH5hNgiM9Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/order.vue?macro=true";
import { default as umweltplaketteu2DJHB0Tv0Meta } from "/vercel/path0/layers/zulassung/pages/umschreibung/umweltplakette.vue?macro=true";
import { default as vorbereitungyKAdO3pfCcMeta } from "/vercel/path0/layers/zulassung/pages/umschreibung/vorbereitung.vue?macro=true";
import { default as _91citySlug_93rpv51ti6bkMeta } from "/vercel/path0/apps/zulassung-digital/pages/umweltplakette/[citySlug].vue?macro=true";
import { default as checkoutjrqkRDOOwfMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue?macro=true";
import { default as erfolge9e7pLZoUBMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue?macro=true";
import { default as indexcunNUl2jQtMeta } from "/vercel/path0/apps/zulassung-digital/pages/umweltplakette/index.vue?macro=true";
import { default as uploadno4QK8oc2JMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue?macro=true";
import { default as umzugg1DBuL0UNFMeta } from "/vercel/path0/layers/zulassung/pages/umzug.vue?macro=true";
import { default as widerrufsrechtQmYuV9tGV4Meta } from "/vercel/path0/layers/base/pages/widerrufsrecht.vue?macro=true";
import { default as altes_45kennzeichenTWb6C6JynDMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdeL0BKhnQqwNMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/behoerde.vue?macro=true";
import { default as finWWIf5Jv1zbMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/fin.vue?macro=true";
import { default as neues_45kennzeichenn59hJdhNajMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/neues-kennzeichen.vue?macro=true";
import { default as nummer_45zb26y1ZIHxMOVMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/nummer-zb2.vue?macro=true";
import { default as reservierungGzpuL3YdArMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45zb1riIupFrxRhMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb2vm3SEpvxm9Meta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steuerWaFpyZ4THzMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerdeSApeRQCOopMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45portallSLddmPI9tMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue?macro=true";
import { default as wunschkennzeichen_45sucheE3VfhOqwknMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as fahrzeugyfZPF0YKd3Meta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/fahrzeug.vue?macro=true";
import { default as indexR4ukdEyrlAMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/index.vue?macro=true";
import { default as kfz_45kennzeichenWIxeXkvpKQMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/kfz-kennzeichen.vue?macro=true";
import { default as orderpd9norPHK1Meta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/order.vue?macro=true";
import { default as umweltplaketteVIMzvMax2jMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/umweltplakette.vue?macro=true";
import { default as vorbereitungQpCgS4BP6GMeta } from "/vercel/path0/layers/zulassung/pages/wiederzulassung/vorbereitung.vue?macro=true";
import { default as _91citySlug_93q72WpWomRxMeta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue?macro=true";
import { default as _91districtSlug_93bXpTQDAoNpMeta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue?macro=true";
import { default as checkoutHMBRBWVVmPMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue?macro=true";
import { default as erfolgb0SKqCW1a9Meta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue?macro=true";
import { default as indexk21CRwAde6Meta } from "/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/index.vue?macro=true";
import { default as reservierenjIHAT1n7pPMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue?macro=true";
import { default as zulassungsstatusntfbqloinEMeta } from "/vercel/path0/layers/zulassung/pages/zulassungsstatus.vue?macro=true";
import { default as _91citySlug_93yZOKsECep4Meta } from "/vercel/path0/apps/zulassung-digital/pages/zulassungsstelle/[citySlug].vue?macro=true";
import { default as zustandfySzn1VliuMeta } from "/vercel/path0/layers/zulassung/pages/zustand.vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    meta: agbh99VXTzrOeMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/agb.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-korrigieren",
    path: "/ausserbetriebsetzung/daten-korrigieren",
    meta: daten_45korrigierenrO0Ksm2GjlMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-fin",
    path: "/ausserbetriebsetzung/daten/fin",
    meta: fin3x1wlAOIRDMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-generell",
    path: "/ausserbetriebsetzung/daten/generell",
    meta: generellcpwFBDX6zEMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-reservierung",
    path: "/ausserbetriebsetzung/daten/reservierung",
    meta: reservierungcJlHMY13vFMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-hinten",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten",
    meta: sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-vorne",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne",
    meta: sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-zb1",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-zb1",
    meta: sicherheitscode_45zb11iCijwbB8VMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung",
    path: "/ausserbetriebsetzung",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue")
  },
  {
    name: "ausserbetriebsetzung-order",
    path: "/ausserbetriebsetzung/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-fertig",
    path: "/ausserbetriebsetzung/upload/fertig",
    meta: fertigYCJr69cVHpMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue")
  },
  {
    name: "ausserbetriebsetzung-upload",
    path: "/ausserbetriebsetzung/upload",
    meta: indexco3tI9qwy6Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-hinten",
    path: "/ausserbetriebsetzung/upload/sc-hinten",
    meta: sc_45hintenHFfWWiaAm9Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-vorn",
    path: "/ausserbetriebsetzung/upload/sc-vorn",
    meta: sc_45vorn5euoZ1U7wvMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-zb1",
    path: "/ausserbetriebsetzung/upload/sc-zb1",
    meta: sc_45zb19z9DdbEWn4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-zb1",
    path: "/ausserbetriebsetzung/upload/zb1",
    meta: zb1ztV6QSFErLMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-vorbereitung",
    path: "/ausserbetriebsetzung/vorbereitung",
    meta: vorbereitungNBPdgYneSVMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzjl1RybKV6eMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/datenschutz.vue")
  },
  {
    name: "halteraenderung-daten-altes-kennzeichen",
    path: "/halteraenderung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/altes-kennzeichen.vue")
  },
  {
    name: "halteraenderung-daten-behoerde",
    path: "/halteraenderung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/behoerde.vue")
  },
  {
    name: "halteraenderung-daten-fin",
    path: "/halteraenderung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/fin.vue")
  },
  {
    name: "halteraenderung-daten-sicherheitscode-zb1",
    path: "/halteraenderung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "halteraenderung-order",
    path: "/halteraenderung/order",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/order.vue")
  },
  {
    name: "halteraenderung-vorbereitung",
    path: "/halteraenderung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/halteraenderung/vorbereitung.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumlDRZsWB8OXMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexApZSyMy6cWMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/index.vue")
  },
  {
    name: "landkreis-district",
    path: "/landkreis/:district()",
    meta: _91district_93S6iD3MK3YwMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/landkreis/[district].vue")
  },
  {
    name: "landkreise",
    path: "/landkreise",
    meta: landkreisehiIl0WSYFOMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/landkreise.vue")
  },
  {
    name: "stadt-city",
    path: "/stadt/:city()",
    meta: _91city_93tlUk7RvyMWMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/stadt/[city].vue")
  },
  {
    name: "staedte",
    path: "/staedte",
    meta: staedteILCBVc6XWzMeta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/staedte.vue")
  },
  {
    name: "umschreibung-daten-altes-kennzeichen",
    path: "/umschreibung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/altes-kennzeichen.vue")
  },
  {
    name: "umschreibung-daten-behoerde",
    path: "/umschreibung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/behoerde.vue")
  },
  {
    name: "umschreibung-daten-fin",
    path: "/umschreibung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/fin.vue")
  },
  {
    name: "umschreibung-daten-neues-kennzeichen",
    path: "/umschreibung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/neues-kennzeichen.vue")
  },
  {
    name: "umschreibung-daten-nummer-zb2",
    path: "/umschreibung/daten/nummer-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/nummer-zb2.vue")
  },
  {
    name: "umschreibung-daten-reservierung",
    path: "/umschreibung/daten/reservierung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/reservierung.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-hinten",
    path: "/umschreibung/daten/sicherheitscode-hinten",
    meta: sicherheitscode_45hintenZuec3ZLB1mMeta || {},
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-hinten.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-vorne",
    path: "/umschreibung/daten/sicherheitscode-vorne",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-vorne.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb1",
    path: "/umschreibung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb2",
    path: "/umschreibung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/sicherheitscode-zb2.vue")
  },
  {
    name: "umschreibung-daten-versicherung-steuer",
    path: "/umschreibung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/versicherung-steuer.vue")
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-behoerde",
    path: "/umschreibung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue")
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-suche",
    path: "/umschreibung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/daten/wunschkennzeichen-suche.vue")
  },
  {
    name: "umschreibung-fahrzeug",
    path: "/umschreibung/fahrzeug",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/fahrzeug.vue")
  },
  {
    name: "umschreibung",
    path: "/umschreibung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/index.vue")
  },
  {
    name: "umschreibung-kfz-kennzeichen",
    path: "/umschreibung/kfz-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/kfz-kennzeichen.vue")
  },
  {
    name: "umschreibung-order",
    path: "/umschreibung/order",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/order.vue")
  },
  {
    name: "umschreibung-umweltplakette",
    path: "/umschreibung/umweltplakette",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/umweltplakette.vue")
  },
  {
    name: "umschreibung-vorbereitung",
    path: "/umschreibung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/umschreibung/vorbereitung.vue")
  },
  {
    name: "umweltplakette-citySlug",
    path: "/umweltplakette/:citySlug()",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/umweltplakette/[citySlug].vue")
  },
  {
    name: "umweltplakette-checkout",
    path: "/umweltplakette/checkout",
    meta: checkoutjrqkRDOOwfMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue")
  },
  {
    name: "umweltplakette-erfolg",
    path: "/umweltplakette/erfolg",
    meta: erfolge9e7pLZoUBMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue")
  },
  {
    name: "umweltplakette",
    path: "/umweltplakette",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/umweltplakette/index.vue")
  },
  {
    name: "umweltplakette-upload",
    path: "/umweltplakette/upload",
    meta: uploadno4QK8oc2JMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue")
  },
  {
    name: "umzug",
    path: "/umzug",
    component: () => import("/vercel/path0/layers/zulassung/pages/umzug.vue")
  },
  {
    name: "widerrufsrecht",
    path: "/widerrufsrecht",
    meta: widerrufsrechtQmYuV9tGV4Meta || {},
    component: () => import("/vercel/path0/layers/base/pages/widerrufsrecht.vue")
  },
  {
    name: "wiederzulassung-daten-altes-kennzeichen",
    path: "/wiederzulassung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/altes-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-daten-behoerde",
    path: "/wiederzulassung/daten/behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/behoerde.vue")
  },
  {
    name: "wiederzulassung-daten-fin",
    path: "/wiederzulassung/daten/fin",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/fin.vue")
  },
  {
    name: "wiederzulassung-daten-neues-kennzeichen",
    path: "/wiederzulassung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/neues-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-daten-nummer-zb2",
    path: "/wiederzulassung/daten/nummer-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/nummer-zb2.vue")
  },
  {
    name: "wiederzulassung-daten-reservierung",
    path: "/wiederzulassung/daten/reservierung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/reservierung.vue")
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb1",
    path: "/wiederzulassung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb2",
    path: "/wiederzulassung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/sicherheitscode-zb2.vue")
  },
  {
    name: "wiederzulassung-daten-versicherung-steuer",
    path: "/wiederzulassung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/versicherung-steuer.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-behoerde",
    path: "/wiederzulassung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-portal",
    path: "/wiederzulassung/daten/wunschkennzeichen-portal",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue")
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-suche",
    path: "/wiederzulassung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue")
  },
  {
    name: "wiederzulassung-fahrzeug",
    path: "/wiederzulassung/fahrzeug",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/fahrzeug.vue")
  },
  {
    name: "wiederzulassung",
    path: "/wiederzulassung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/index.vue")
  },
  {
    name: "wiederzulassung-kfz-kennzeichen",
    path: "/wiederzulassung/kfz-kennzeichen",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/kfz-kennzeichen.vue")
  },
  {
    name: "wiederzulassung-order",
    path: "/wiederzulassung/order",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/order.vue")
  },
  {
    name: "wiederzulassung-umweltplakette",
    path: "/wiederzulassung/umweltplakette",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/umweltplakette.vue")
  },
  {
    name: "wiederzulassung-vorbereitung",
    path: "/wiederzulassung/vorbereitung",
    component: () => import("/vercel/path0/layers/zulassung/pages/wiederzulassung/vorbereitung.vue")
  },
  {
    name: "wunschkennzeichen-citySlug",
    path: "/wunschkennzeichen/:citySlug()",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue")
  },
  {
    name: "wunschkennzeichen-bezirk-districtSlug",
    path: "/wunschkennzeichen/bezirk/:districtSlug()",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue")
  },
  {
    name: "wunschkennzeichen-checkout",
    path: "/wunschkennzeichen/checkout",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue")
  },
  {
    name: "wunschkennzeichen-erfolg",
    path: "/wunschkennzeichen/erfolg",
    meta: erfolgb0SKqCW1a9Meta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue")
  },
  {
    name: "wunschkennzeichen",
    path: "/wunschkennzeichen",
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/wunschkennzeichen/index.vue")
  },
  {
    name: "wunschkennzeichen-reservieren",
    path: "/wunschkennzeichen/reservieren",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue")
  },
  {
    name: "zulassungsstatus",
    path: "/zulassungsstatus",
    component: () => import("/vercel/path0/layers/zulassung/pages/zulassungsstatus.vue")
  },
  {
    name: "zulassungsstelle-citySlug",
    path: "/zulassungsstelle/:citySlug()",
    meta: _91citySlug_93yZOKsECep4Meta || {},
    component: () => import("/vercel/path0/apps/zulassung-digital/pages/zulassungsstelle/[citySlug].vue")
  },
  {
    name: "zustand",
    path: "/zustand",
    component: () => import("/vercel/path0/layers/zulassung/pages/zustand.vue")
  }
]