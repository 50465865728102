import { useRequest } from 'vue-request'
import type { OrderSource, PersonalOrderPosition } from '@gks/prisma'
import type { VehicleType } from '@black-bird/types'

type UmschreibungData = Parameters<ReturnType<typeof useNuxtApp>['$trpc']['personal']['umschreibung']['createOrder']['mutate']>['0']['umschreibung']

export default defineStore('umschreibung', () => {
  const { email, phone } = useSharedOrderInfo()

  const data = ref<UmschreibungData>({
    fin: '',
    scZb1: '',
    scVorn: '',
    scHinten: '',
    scZb2: '',
    nummerZb2: '',
    aktuellesKennzeichen: {
      kombination: {
        token: '',
        letters: '',
        numbers: ''
      },
      elektro: false
    },
    bankAccount: {
      iban: ''
    },
    evbNummer: '',
    naechstesKennzeichen: {
      type: 'reservierung',
      elektro: false,
      pin: '',
      kombination: {
        token: '',
        letters: '',
        numbers: ''
      },
    }
  })

  const officeId = ref('')

  const vehicle = ref<VehicleType>('car')

  type UmschreibungType = 'halterwechsel' | 'umzug'
  const typeOfUmschreibung = ref<UmschreibungType>('halterwechsel')

  const dto = computed(() => {
    const { fin, scZb1, scZb2, scHinten, scVorn, naechstesKennzeichen, nummerZb2, evbNummer, aktuellesKennzeichen, bankAccount } = data.value

    const pin = naechstesKennzeichen.type === 'reservierung' ? (naechstesKennzeichen.pin || undefined) : undefined
    const naechstesKennzeichenKombination = naechstesKennzeichen.type === 'reservierung' ? naechstesKennzeichen.kombination : undefined

    const selectedPlate = useWkzSearchesStore().selectedPlate
    const vehicleType = naechstesKennzeichen.type === 'reservierung' ? selectedPlate?.search?.vehicle : undefined

    return {
      email: email.value,
      source: useRuntimeConfig().public.source as OrderSource,
      phone: phone.value,
      umschreibung: {
        fin,
        scZb1,
        scVorn: naechstesKennzeichen?.type === 'vorhanden' ? undefined : (scVorn || null),
        scHinten: naechstesKennzeichen?.type === 'vorhanden' ? undefined : scHinten,
        scZb2: typeOfUmschreibung.value === 'halterwechsel' || naechstesKennzeichen.type !== 'vorhanden' ? scZb2 : undefined,
        nummerZb2: typeOfUmschreibung.value === 'halterwechsel' || naechstesKennzeichen.type !== 'vorhanden' ? nummerZb2 : undefined,
        aktuellesKennzeichen,
        bankAccount,
        evbNummer,
        naechstesKennzeichen: {
          type: naechstesKennzeichen.type,
          elektro: naechstesKennzeichen.elektro,
          pin,
          kombination: naechstesKennzeichenKombination
        }
      },
      vehicleType,
      officeId: officeId.value
    }
  })

  const licensePlatePositions = ref<PersonalOrderPosition[]>([])
  const umweltplakettePositions = ref<PersonalOrderPosition[]>([])

  const positions = computed<PersonalOrderPosition[]>(() => {
    // * Prevent positions if user selected zufallskennzeichen
    if (data.value.naechstesKennzeichen.type === 'zufall') return []
  
    return [
      ...licensePlatePositions.value,
      ...umweltplakettePositions.value
    ]
  })

  const { runAsync: createOrder, loading: createOrderLoading } = useRequest(async () => {
    // Sometimes people copy the url and try to create an order without the required data in local storage
    // In this case we should navigate the user to the start page and not let them create an order
    if (!dto.value.email) {
      return navigateTo('/', { replace: true })
    }

    const googleClientId = await getGoogleClientId()

    const { checkoutSessionUrl } = await useNuxtApp().$trpc.personal.umschreibung.createOrder.mutate({
      ...dto.value,
      positions: positions.value,
      googleClientId
    })
  
    window.location.href = checkoutSessionUrl
    // fake wait until redirect happens
    await new Promise(res => setTimeout(res, 10000))
  }, { manual: true })

  const { data: total, loading: totalLoading } = useRequest(() => {
    return useNuxtApp().$trpc.public.getCheckoutTotal.query({
      positions: positions.value,
      geschaeftsvorfall: 'umschreibung',
      reservierung: data.value.naechstesKennzeichen.type === 'reservierung'
    })
  }, {
    refreshDeps: [() => positions.value.length, () => data.value.naechstesKennzeichen.type],
  })

  return {
    data,
    dto,
    createOrder,
    createOrderLoading,
    officeId,
    positions,
    licensePlatePositions,
    umweltplakettePositions,
    vehicle,
    total,
    totalLoading,
    typeOfUmschreibung
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    pick: ['data', 'officeId', 'licensePlatePositions', 'umweltplakettePositions', 'vehicle', 'typeOfUmschreibung']
  }
})
