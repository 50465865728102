import type { WeakVehicleType } from '@gks/types'

export default function getQtyFromVehicle (vehicle: WeakVehicleType) {
  switch (vehicle) {
    case 'car':
    case 'green-car':
    case 'tractor':
    case 'lkw':
      return 2
    case 'motorcycle':
    case 'trailer':
    case 'light-motorcycle':
      return 1
    default:
      return ((x: never) => x)(vehicle)
  }
}
